import LargeButton from '@/components/Views/Buttons';
import React from 'react';
import { useDispatchUi } from 'context/ui';
import { MUZOLOGY_SIGNUP_URL } from '@/lib/defaults';

export const SignupBlock = ({ heading = null, smallHeading = null, className = '', videoLink = null, id }) => {
    const dispatch: any = useDispatchUi();

    return (
        <section id={id} className={`text-center flex flex-col ${className}`}>
            <div className='text-2xl font-medium text-[#6B7280]'>{heading ?? 'Join Now'}</div>
            <div className='text-2xl md:text-[28px] font-medium text-[#131313] whitespace-nowrap mt-3'>Try Muzology Free Today!</div>
            <div className='button-group center mt-6'>
                <LargeButton className='purple-button w-[200px]' text='Sign Up My Child' href={MUZOLOGY_SIGNUP_URL} id={id + '-sign-up-my-child'} />
                <LargeButton className='blue-button w-[200px]' text='Sign Up As Educator' href='/signup' id={id + '-sign-up-as-educator'} />
                <LargeButton
                    className='dark-button w-[200px]'
                    text='How it Works'
                    onClick={() => dispatch({ type: 'PLAY_VIDEO', payload: videoLink })}
                    rightDelta={true}
                    id={id + '-how-it-works'}

                />
            </div>
        </section>
    );
};

export default SignupBlock;
